import DefaultErrorPage from 'next/error';
import Head from 'next/head';

export const MissingResource = () => {
  return (
    <>
      <Head>
        <meta
          content="noindex"
          name="robots"
        />
      </Head>
      <DefaultErrorPage statusCode={404} />
    </>
  );
};
