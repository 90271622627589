import {
  type UserProfile_userProfile$data,
  type UserProfile_userProfile$key,
} from '@/__generated__/relay/UserProfile_userProfile.graphql.js';
import { UserProfileFragment } from '@/documents/fragments/UserProfile.js';
import { normalizeUsername } from '@/utilities/normalizeUsername.js';
import { createContext, type ReactNode, useMemo } from 'react';
// eslint-disable-next-line canonical/no-restricted-imports
import { useFragment } from 'react-relay';

export type UserProfile = UserProfile_userProfile$data & {
  isTopIndependent: boolean;
  profileDisplayUsername: string;
};

export const UserProfileContext = createContext<UserProfile | null>(null);

export const UserProfileProvider = ({
  children,
  queryRef,
}: {
  readonly children: ReactNode;
  readonly queryRef: UserProfile_userProfile$key;
}) => {
  const userProfileByUsername = useFragment<UserProfile_userProfile$key>(
    UserProfileFragment,
    queryRef,
  );

  const value = useMemo(() => {
    if (userProfileByUsername) {
      return {
        ...userProfileByUsername,
        displayUsername: normalizeUsername(
          userProfileByUsername.displayUsername,
        ),
        isTopIndependent:
          userProfileByUsername.topIndependentStatus === 'OPTED_IN',
        profileDisplayUsername: userProfileByUsername.displayUsername,
      };
    }

    return null;
  }, [userProfileByUsername]);

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
};
