import { graphql } from 'relay-runtime';

export const UserProfileFragment = graphql`
  fragment UserProfile_userProfile on UserProfile {
    id
    analyticsId
    displayUsername
    firstName
    lastName
    location
    profileDisplayType
    title
    bio
    visitorCanEdit
    generalInquiryCtaIsVisible
    hasActivePortfolio
    primaryJobPostingCategory
    topIndependentStatus
    brandCustomization {
      logo {
        logoImage {
          id
          uid
        }
      }
    }
    belongsToContraLists {
      edges {
        node {
          nid
        }
      }
    }
    roles {
      edges {
        node {
          id
          name
        }
      }
    }
    avatarImage {
      id
      uid
      height
      url
      width
      animated
    }
    portfolioProjects(type: PROJECT, excludeDrafts: true) {
      edges {
        node {
          analyticsId
          cover {
            ... on PortfolioProjectImageCover {
              __typename
              image {
                id
                animated
                height
                originalFormat
                sizeBytes
                uid
                width
              }
            }
            ... on PortfolioProjectTextCover {
              __typename
              style
            }
          }
          id
          slug
          title
          roles {
            edges {
              node {
                id
                name
              }
            }
          }
          organizations {
            edges {
              node {
                id
                name
              }
            }
          }
          cover {
            ... on PortfolioProjectImageCover {
              __typename
              image {
                id
                animated
                height
                originalFormat
                sizeBytes
                uid
                width
              }
            }
            ... on PortfolioProjectTextCover {
              __typename
              style
            }
          }
        }
      }
    }
    productizedServices(excludeDrafts: true) {
      edges {
        node {
          id
          slug
          title
          portfolioProjects {
            edges {
              node {
                id
                slug
              }
            }
          }
          productizedServiceType
          duration {
            amount
            interval
          }
          price {
            type
            interval
            amount
            timeline
          }
        }
      }
    }
    externalLinks {
      edges {
        node {
          id
          label
          url
          socialMediaSite {
            id
            nid
          }
        }
      }
    }
    independentPortfolioTemplateTheme {
      id
      developmentSlug
      displayName
      template {
        id
        developmentSlug
        displayName
      }
      themeCustomization {
        id
        accentFontPack {
          id
          nid
        }
        backgroundColor
        backgroundStyle {
          id
          nid
        }
        bodyFontPack {
          id
          nid
        }
        fontColorPrimary
        fontColorSecondary
        fontPack {
          id
          nid
        }
        headingFontPack {
          id
          nid
        }

        id
        layout {
          projectsVariant {
            id
            nid
          }
          servicesVariant {
            id
            nid
          }
        }
        logoImage {
          id
          uid
          sizeBytes
        }
      }
    }
    userReviews {
      count
      edges {
        node {
          id
          commentOnlyVisibleToRecipient
          createdAt
          onlyVisibleToRecipient
          isPositiveReview
          reviewText
          author {
            analyticsId
            id
            firstName
            lastName
            displayUsername
            onboardingType
            avatarImage {
              id
              uid
              url
            }
            organization {
              name
              logoImage {
                id
                uid
                url
              }
            }
          }
          associatedWork {
            paidProjectProfile {
              id
              client {
                id
              }
            }
            portfolioProject {
              id
            }
            productizedService {
              id
            }
          }
          ...UserReview_userReview
        }
      }
    }
  }
`;
