/**
 * Method to normalize a username in the same way that Contra API does.
 * Removes all non-alphanumeric characters.
 * See: https://github.com/contra/contra-database/blob/main/src/procedures/R__0000_normalize_username.sql
 * @param {string} username - username to normalize
 * @returns A normalized username with all non-alphanumeric characters removed
 */
export const normalizeUsername = (username: string): string => {
  return username.replaceAll(/[^\da-z]/giu, '').toLowerCase();
};
