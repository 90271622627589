/**
 * @generated SignedSource<<402cc5c1a03a5ed04d2cf7d5cc39f17a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContraUserListNid = "QUICK_RESPONDER" | "TOP_DATA_ANALYST" | "TOP_DESIGNER" | "TOP_ENGINEER" | "TOP_FINANCE" | "TOP_MARKETER" | "TOP_PRODUCER" | "TOP_PRODUCT" | "TOP_SOCIAL_MEDIA_MANAGER" | "TOP_SPECIALIST" | "TOP_STRATEGIST" | "TOP_SUPPORT" | "TOP_VIDEO_EDITOR" | "TOP_WEB3_DEVELOPER" | "TOP_WRITER" | "%future added value";
export type OnboardingType = "CLIENT" | "CONTRACTOR" | "%future added value";
export type PortfolioProjectTextCoverStyle = "COLOR_BLACK" | "COLOR_BLUE" | "COLOR_GREEN" | "COLOR_ORANGE" | "COLOR_PURPLE" | "COLOR_SALMON" | "COLOR_WATERMELON" | "COLOR_WHITE" | "COLOR_YELLOW" | "SHAPE_COINS_DARK" | "SHAPE_COINS_LIGHT" | "SHAPE_COLLABORATION_DARK" | "SHAPE_COLLABORATION_LIGHT" | "SHAPE_FOCUS_DARK" | "SHAPE_FOCUS_LIGHT" | "SHAPE_GAIN_DARK" | "SHAPE_GAIN_LIGHT" | "SHAPE_STAIRS_DARK" | "SHAPE_STAIRS_LIGHT" | "SHAPE_WAVE_DARK" | "SHAPE_WAVE_LIGHT" | "SVG_BLUE" | "SVG_BLUE2" | "SVG_LINES_BLUE" | "SVG_LINES_GREEN" | "SVG_LINES_ORANGE" | "SVG_LINES_PURPLE" | "SVG_LINES_SALMON" | "SVG_LINES_YELLOW" | "SVG_WHITE" | "SVG_WHITE2" | "SVG_YELLOW" | "SVG_YELLOW2" | "VAPOR_GRADIENT_BLUE_DARK" | "VAPOR_GRADIENT_BLUE_LIGHT" | "VAPOR_GRADIENT_GREEN_DARK" | "VAPOR_GRADIENT_GREEN_LIGHT" | "VAPOR_GRADIENT_PURPLE_DARK" | "VAPOR_GRADIENT_PURPLE_LIGHT" | "VAPOR_GRADIENT_SALMON_DARK" | "VAPOR_GRADIENT_SALMON_LIGHT" | "VAPOR_GRADIENT_WATERMELON_DARK" | "VAPOR_GRADIENT_WATERMELON_LIGHT" | "VAPOR_GRADIENT_WHITE_DARK" | "VAPOR_GRADIENT_YELLOW_LIGHT" | "%future added value";
export type ProductizedServiceDurationInterval = "DAY" | "WEEK" | "%future added value";
export type ProductizedServicePriceInterval = "DAY" | "HOUR" | "MONTH" | "WEEK" | "%future added value";
export type ProductizedServicePriceType = "CONTACT_FOR_PRICING" | "FIXED_PRICE" | "RATE" | "%future added value";
export type ProductizedServiceType = "BUY_NOW_INSTANT_PURCHASE" | "BUY_NOW_OTHER" | "BUY_NOW_REQUEST" | "NOT_PURCHASABLE" | "%future added value";
export type ProfileDisplayType = "BASIC" | "BLOG" | "RESOURCE" | "%future added value";
export type SocialMediaSiteNid = "ANGELLIST" | "ARTSTATION" | "AWWWARDS" | "BEHANCE" | "CARBONMADE" | "DEV" | "DEVIANTART" | "DRIBBBLE" | "FACEBOOK" | "FIVERR" | "FREELANCER" | "FWA" | "GITHUB" | "GITLAB" | "HASHNODE" | "INSTAGRAM" | "LINKEDIN" | "MEDIUM" | "PATREON" | "PINTEREST" | "SOUNDCLOUD" | "STACKOVERFLOW" | "TIKTOK" | "TUMBLR" | "TWITCH" | "TWITTER" | "UPWORK" | "VIMEO" | "VKONTAKTE" | "WNW" | "YOUTUBE" | "%future added value";
export type TopIndependentStatus = "DISCOVERED" | "OPTED_IN" | "OPTED_OUT" | "UNDISCOVERED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserProfile_userProfile$data = {
  readonly analyticsId: number;
  readonly avatarImage: {
    readonly animated: boolean;
    readonly height: number;
    readonly id: string;
    readonly uid: string;
    readonly url: string;
    readonly width: number;
  } | null;
  readonly belongsToContraLists: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly nid: ContraUserListNid | null;
      };
    }>;
  };
  readonly bio: string | null;
  readonly brandCustomization: {
    readonly logo: {
      readonly logoImage: {
        readonly id: string;
        readonly uid: string;
      };
    } | null;
  } | null;
  readonly displayUsername: string;
  readonly externalLinks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly label: string | null;
        readonly socialMediaSite: {
          readonly id: string;
          readonly nid: SocialMediaSiteNid;
        } | null;
        readonly url: string;
      };
    }>;
  };
  readonly firstName: string | null;
  readonly generalInquiryCtaIsVisible: boolean;
  readonly hasActivePortfolio: boolean;
  readonly id: string;
  readonly independentPortfolioTemplateTheme: {
    readonly developmentSlug: string;
    readonly displayName: string;
    readonly id: string;
    readonly template: {
      readonly developmentSlug: string;
      readonly displayName: string;
      readonly id: string;
    };
    readonly themeCustomization: {
      readonly accentFontPack: {
        readonly id: string;
        readonly nid: string;
      } | null;
      readonly backgroundColor: string;
      readonly backgroundStyle: {
        readonly id: string;
        readonly nid: string;
      };
      readonly bodyFontPack: {
        readonly id: string;
        readonly nid: string;
      } | null;
      readonly fontColorPrimary: string;
      readonly fontColorSecondary: string;
      readonly fontPack: {
        readonly id: string;
        readonly nid: string;
      };
      readonly headingFontPack: {
        readonly id: string;
        readonly nid: string;
      } | null;
      readonly id: string;
      readonly layout: {
        readonly projectsVariant: {
          readonly id: string;
          readonly nid: string;
        };
        readonly servicesVariant: {
          readonly id: string;
          readonly nid: string;
        };
      };
      readonly logoImage: {
        readonly id: string;
        readonly sizeBytes: number;
        readonly uid: string;
      } | null;
    } | null;
  } | null;
  readonly lastName: string | null;
  readonly location: string | null;
  readonly portfolioProjects: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly analyticsId: number;
        readonly cover: {
          readonly __typename: "PortfolioProjectImageCover";
          readonly image: {
            readonly animated: boolean;
            readonly height: number;
            readonly id: string;
            readonly originalFormat: string;
            readonly sizeBytes: number;
            readonly uid: string;
            readonly width: number;
          };
        } | {
          readonly __typename: "PortfolioProjectTextCover";
          readonly style: PortfolioProjectTextCoverStyle;
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        };
        readonly id: string;
        readonly organizations: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly name: string;
            };
          }>;
        };
        readonly roles: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly name: string;
            };
          }>;
        };
        readonly slug: string;
        readonly title: string;
      };
    }>;
  };
  readonly primaryJobPostingCategory: string | null;
  readonly productizedServices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly duration: {
          readonly amount: number;
          readonly interval: ProductizedServiceDurationInterval;
        } | null;
        readonly id: string;
        readonly portfolioProjects: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly slug: string;
            };
          }>;
        };
        readonly price: {
          readonly amount: number | null;
          readonly interval: ProductizedServicePriceInterval | null;
          readonly timeline: string | null;
          readonly type: ProductizedServicePriceType;
        };
        readonly productizedServiceType: ProductizedServiceType | null;
        readonly slug: string;
        readonly title: string;
      };
    }>;
  };
  readonly profileDisplayType: ProfileDisplayType;
  readonly roles: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
  readonly title: string | null;
  readonly topIndependentStatus: TopIndependentStatus;
  readonly userReviews: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly associatedWork: {
          readonly paidProjectProfile: {
            readonly client: {
              readonly id: string;
            };
            readonly id: string;
          } | null;
          readonly portfolioProject: {
            readonly id: string;
          } | null;
          readonly productizedService: {
            readonly id: string;
          } | null;
        };
        readonly author: {
          readonly analyticsId: number;
          readonly avatarImage: {
            readonly id: string;
            readonly uid: string;
            readonly url: string;
          } | null;
          readonly displayUsername: string;
          readonly firstName: string | null;
          readonly id: string;
          readonly lastName: string | null;
          readonly onboardingType: OnboardingType;
          readonly organization: {
            readonly logoImage: {
              readonly id: string;
              readonly uid: string;
              readonly url: string;
            } | null;
            readonly name: string;
          } | null;
        };
        readonly commentOnlyVisibleToRecipient: boolean;
        readonly createdAt: ContraAPIScalars.DateTime;
        readonly id: string;
        readonly isPositiveReview: boolean;
        readonly onlyVisibleToRecipient: boolean;
        readonly reviewText: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"UserReview_userReview">;
      };
    }>;
  };
  readonly visitorCanEdit: boolean;
  readonly " $fragmentType": "UserProfile_userProfile";
};
export type UserProfile_userProfile$key = {
  readonly " $data"?: UserProfile_userProfile$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfile_userProfile">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "analyticsId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayUsername",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nid",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v0/*: any*/),
  (v8/*: any*/)
],
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Role",
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": (v9/*: any*/),
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "animated",
  "storageKey": null
},
v15 = {
  "kind": "Literal",
  "name": "excludeDrafts",
  "value": true
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sizeBytes",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "interval",
  "storageKey": null
},
v21 = [
  (v0/*: any*/),
  (v7/*: any*/)
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "developmentSlug",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v24 = [
  (v0/*: any*/),
  (v6/*: any*/),
  (v12/*: any*/)
],
v25 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfile_userProfile",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profileDisplayType",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitorCanEdit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generalInquiryCtaIsVisible",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasActivePortfolio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryJobPostingCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topIndependentStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandCustomization",
      "kind": "LinkedField",
      "name": "brandCustomization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BrandCustomizationLogo",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContraImage",
              "kind": "LinkedField",
              "name": "logoImage",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserListConnection",
      "kind": "LinkedField",
      "name": "belongsToContraLists",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserListUserProfileEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserList",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfileRoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserAccountRoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": (v10/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ContraImage",
      "kind": "LinkedField",
      "name": "avatarImage",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v6/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v15/*: any*/),
        {
          "kind": "Literal",
          "name": "type",
          "value": "PROJECT"
        }
      ],
      "concreteType": "UserProfilePortfolioProjectConnection",
      "kind": "LinkedField",
      "name": "portfolioProjects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfilePortfolioProjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PortfolioProject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "cover",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v16/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContraImage",
                          "kind": "LinkedField",
                          "name": "image",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v14/*: any*/),
                            (v11/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "originalFormat",
                              "storageKey": null
                            },
                            (v17/*: any*/),
                            (v6/*: any*/),
                            (v13/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "PortfolioProjectImageCover",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v16/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "style",
                          "storageKey": null
                        }
                      ],
                      "type": "PortfolioProjectTextCover",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v0/*: any*/),
                (v18/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PortfolioProjectRoleConnection",
                  "kind": "LinkedField",
                  "name": "roles",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PortfolioProjectRoleEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": (v10/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PortfolioProjectOrganizationConnection",
                  "kind": "LinkedField",
                  "name": "organizations",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PortfolioProjectOrganizationEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Organization",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v9/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "portfolioProjects(excludeDrafts:true,type:\"PROJECT\")"
    },
    {
      "alias": null,
      "args": [
        (v15/*: any*/)
      ],
      "concreteType": "UserProfileProductizedServiceConnection",
      "kind": "LinkedField",
      "name": "productizedServices",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfileProductizedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductizedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v18/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductizedServicePortfolioProjectConnection",
                  "kind": "LinkedField",
                  "name": "portfolioProjects",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductizedServicePortfolioProjectEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PortfolioProject",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v18/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "productizedServiceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductizedServiceDuration",
                  "kind": "LinkedField",
                  "name": "duration",
                  "plural": false,
                  "selections": [
                    (v19/*: any*/),
                    (v20/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductizedServicePrice",
                  "kind": "LinkedField",
                  "name": "price",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    (v20/*: any*/),
                    (v19/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timeline",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "productizedServices(excludeDrafts:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfileExternalLinkConnection",
      "kind": "LinkedField",
      "name": "externalLinks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserProfileExternalLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserProfileExternalLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                (v12/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SocialMediaSite",
                  "kind": "LinkedField",
                  "name": "socialMediaSite",
                  "plural": false,
                  "selections": (v21/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndependentPortfolioTemplateTheme",
      "kind": "LinkedField",
      "name": "independentPortfolioTemplateTheme",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v22/*: any*/),
        (v23/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "IndependentPortfolioTemplate",
          "kind": "LinkedField",
          "name": "template",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v22/*: any*/),
            (v23/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ThemeCustomization",
          "kind": "LinkedField",
          "name": "themeCustomization",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ThemeFontPack",
              "kind": "LinkedField",
              "name": "accentFontPack",
              "plural": false,
              "selections": (v21/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "backgroundColor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ThemeBackgroundStyle",
              "kind": "LinkedField",
              "name": "backgroundStyle",
              "plural": false,
              "selections": (v21/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ThemeFontPack",
              "kind": "LinkedField",
              "name": "bodyFontPack",
              "plural": false,
              "selections": (v21/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fontColorPrimary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fontColorSecondary",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ThemeFontPack",
              "kind": "LinkedField",
              "name": "fontPack",
              "plural": false,
              "selections": (v21/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ThemeFontPack",
              "kind": "LinkedField",
              "name": "headingFontPack",
              "plural": false,
              "selections": (v21/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomizationLayout",
              "kind": "LinkedField",
              "name": "layout",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ThemeProjectLayout",
                  "kind": "LinkedField",
                  "name": "projectsVariant",
                  "plural": false,
                  "selections": (v21/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ThemeServiceLayout",
                  "kind": "LinkedField",
                  "name": "servicesVariant",
                  "plural": false,
                  "selections": (v21/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ContraImage",
              "kind": "LinkedField",
              "name": "logoImage",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v6/*: any*/),
                (v17/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserReviewConnection",
      "kind": "LinkedField",
      "name": "userReviews",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserReviewEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UserReview",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "commentOnlyVisibleToRecipient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "onlyVisibleToRecipient",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isPositiveReview",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reviewText",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserProfile",
                  "kind": "LinkedField",
                  "name": "author",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "onboardingType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ContraImage",
                      "kind": "LinkedField",
                      "name": "avatarImage",
                      "plural": false,
                      "selections": (v24/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Organization",
                      "kind": "LinkedField",
                      "name": "organization",
                      "plural": false,
                      "selections": [
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ContraImage",
                          "kind": "LinkedField",
                          "name": "logoImage",
                          "plural": false,
                          "selections": (v24/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserReviewAssociatedWork",
                  "kind": "LinkedField",
                  "name": "associatedWork",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PaidProjectProfile",
                      "kind": "LinkedField",
                      "name": "paidProjectProfile",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "UserProfile",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": (v25/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PortfolioProject",
                      "kind": "LinkedField",
                      "name": "portfolioProject",
                      "plural": false,
                      "selections": (v25/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProductizedService",
                      "kind": "LinkedField",
                      "name": "productizedService",
                      "plural": false,
                      "selections": (v25/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserReview_userReview"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserProfile",
  "abstractKey": null
};
})();

(node as any).hash = "3a3bff2d151698399ef3422cb458de56";

export default node;
